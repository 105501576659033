@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
:root {
  --bg: linear-gradient(to right,#68B7D9,#7AD0C6,#8AE5BA,#A8E1DB);
}
body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto',sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  background-image: linear-gradient(to right,#68B7D9,#7AD0C6,#8AE5BA,#A8E1DB);
  background-image: var(--bg);
}
/* :root {
  --main-content: #fff;
  --a: #000;
  --card-bg: #f7fafa;
  --card-border: #a8e1d8;
  --icon: #66b4b5;
  --card-subtitle: #66b4b5;
  --card-text: #ababa6;
  --btn1-bg: #fff;
  --btn1-border: #66b4b5;
  --btn1-color: #68b7d9;
  --btn2-bg: -webkit-linear-gradient(right, #8ae5ba, #66b4b5);
  --btn2-border: #66b4b5;
  --btn2-color: #fff;
} */

:root {
  --main-content: rgba(255, 255, 255, 0.2);
  --a: #000;
  --card-bg: rgba(255, 255, 255, 0.2);
  --card-border: #a8e1d8;
  --icon: #66b4b5;
  --card-subtitle: #418283;
  --card-text: #0000008e;
  --btn1-bg: rgba(255, 255, 255, 0.2);
  --btn1-border: #66b4b5;
  --btn1-color: #0000008e;
  --btn2-bg: -webkit-linear-gradient(right, #8ae5ba, #66b4b5);
  --btn2-border: #66b4b5;
  --btn2-color: rgba(255, 255, 255, 0.692);
}

.mainContent {
  background-color: rgba(255, 255, 255, 0.2);
  background-color: var(--main-content);
  border-radius: 20px;
}
.logo {
  height: 50px;
  width: 50px;
}
.nav-link {
  padding: 2%;
  text-align: center;
}
a,
a:hover {
  font-family: Roboto;
  font-weight: bold;
  font-size: 12px;
  text-decoration: none;
  color: #000;
  color: var(--a);
}
.card {
  border-radius: 50px;
  background-color: rgba(255, 255, 255, 0.2);
  background-color: var(--card-bg);
  border: 2px solid #a8e1d8;
  border: 2px solid var(--card-border);
  max-height: 250px;
  overflow: hidden;
  margin: 20px auto;
}
.icon {
  color: #66b4b5;
  color: var(--icon);
  font-size: 24px;
}
.card-subtitle {
  font-size: 18px;
  font-weight: bold;
  color: #418283;
  color: var(--card-subtitle);
}
.card-text {
  color: #0000008e;
  color: var(--card-text);
  font-size: 14px;
}
#myImage {
  height: 120px;
  width: 120px;
  border: none;
  overflow: hidden;
}
.img-thumbnail {
  background-color: unset;
  border: none;
}
.social {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#linkedin:hover {
  color: #0066b5;
}
#instagram:hover {
  color: #e4405f;
}
#twitter:hover,
#telegram:hover {
  color: #55acee;
}
.major-title {
  font-weight: bold;
  font-size: 24px;
  color: #000;
  color: var(--a);
}
.minor-title {
  font-weight: bold;
  font-size: 14px;
  color: #000;
  color: var(--a);
}
.btn:hover,
.btn:focus {
  text-decoration: none;
  color: #fff;
  border: none;
}
.button1,
.button2:hover {
  /* border: 1px solid var(--btn1-border); */
  background-color: rgba(255, 255, 255, 0.2);
  background-color: var(--btn1-bg);
  background-image: none;
  color: #0000008e;
  color: var(--btn1-color);
  font-size: 14px;
  font-weight: bold;
  width: 95px;
}
.button2,
.button1:hover {
  /* border: 1px solid var(--btn2-border); */
  background-image: -webkit-linear-gradient(right, #8ae5ba, #66b4b5);
  background-image: var(--btn2-bg);
  color: rgba(255, 255, 255, 0.692);
  color: var(--btn2-color);
  font-size: 14px;
  font-weight: bold;
  width: 95px;
}
#buymeacoffee {
  border: none;
  width: 90%;
}
#buymeacoffee:hover {
  /* background-image: -webkit-linear-gradient(#ff5f5f, #ff5f5f); */
  background-image: -webkit-linear-gradient(
    right,
    rgb(255 95 95),
    rgb(191 28 28)
  );
}
.form-control,
.form-control:focus {
  background-color: rgba(255, 255, 255, 0.2);
  outline: none;
  border: none;
  box-shadow: none;
}
.glassmorph {
  border: 2px solid transparent;
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
  background-clip: border-box;
  box-shadow: 10px 10px 10px rgba(46, 58, 64, 0.03);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
}
.neumorph {
  box-shadow: -10px 10px 20px #7ab7b3, 10px -10px 20px #a6f7f3;
  background-color: transparent;
  border: #64b4b5;
}

/* Loader */

.loader {
  position: relative;
  width: 75px;
  height: 100px;
}
.loader__bar {
  position: absolute;
  bottom: 0;
  width: 10px;
  height: 50%;
  background: #66b4b5;
  background: var(--btn1-border);
  transform-origin: center bottom;
  /* box-shadow: 1px 1px 0 rgba(0, 0, 0, 0.2); */
}
.loader__bar:nth-child(1) {
  left: 0px;
  transform: scale(1, 0.2);
  -webkit-animation: barUp1 4s infinite;
          animation: barUp1 4s infinite;
}
.loader__bar:nth-child(2) {
  left: 15px;
  transform: scale(1, 0.4);
  -webkit-animation: barUp2 4s infinite;
          animation: barUp2 4s infinite;
}
.loader__bar:nth-child(3) {
  left: 30px;
  transform: scale(1, 0.6);
  -webkit-animation: barUp3 4s infinite;
          animation: barUp3 4s infinite;
}
.loader__bar:nth-child(4) {
  left: 45px;
  transform: scale(1, 0.8);
  -webkit-animation: barUp4 4s infinite;
          animation: barUp4 4s infinite;
}
.loader__bar:nth-child(5) {
  left: 60px;
  transform: scale(1, 1);
  -webkit-animation: barUp5 4s infinite;
          animation: barUp5 4s infinite;
}
.loader__ball {
  position: absolute;
  bottom: 10px;
  left: 0;
  width: 10px;
  height: 10px;
  background: #ff5f5f;
  border-radius: 50%;
  -webkit-animation: ball 4s infinite;
          animation: ball 4s infinite;
}
@-webkit-keyframes ball {
  0% {
    transform: translate(0, 0);
  }
  5% {
    transform: translate(8px, -14px);
  }
  10% {
    transform: translate(15px, -10px);
  }
  17% {
    transform: translate(23px, -24px);
  }
  20% {
    transform: translate(30px, -20px);
  }
  27% {
    transform: translate(38px, -34px);
  }
  30% {
    transform: translate(45px, -30px);
  }
  37% {
    transform: translate(53px, -44px);
  }
  40% {
    transform: translate(60px, -40px);
  }
  50% {
    transform: translate(60px, 0);
  }
  57% {
    transform: translate(53px, -14px);
  }
  60% {
    transform: translate(45px, -10px);
  }
  67% {
    transform: translate(37px, -24px);
  }
  70% {
    transform: translate(30px, -20px);
  }
  77% {
    transform: translate(22px, -34px);
  }
  80% {
    transform: translate(15px, -30px);
  }
  87% {
    transform: translate(7px, -44px);
  }
  90% {
    transform: translate(0, -40px);
  }
  100% {
    transform: translate(0, 0);
  }
}
@keyframes ball {
  0% {
    transform: translate(0, 0);
  }
  5% {
    transform: translate(8px, -14px);
  }
  10% {
    transform: translate(15px, -10px);
  }
  17% {
    transform: translate(23px, -24px);
  }
  20% {
    transform: translate(30px, -20px);
  }
  27% {
    transform: translate(38px, -34px);
  }
  30% {
    transform: translate(45px, -30px);
  }
  37% {
    transform: translate(53px, -44px);
  }
  40% {
    transform: translate(60px, -40px);
  }
  50% {
    transform: translate(60px, 0);
  }
  57% {
    transform: translate(53px, -14px);
  }
  60% {
    transform: translate(45px, -10px);
  }
  67% {
    transform: translate(37px, -24px);
  }
  70% {
    transform: translate(30px, -20px);
  }
  77% {
    transform: translate(22px, -34px);
  }
  80% {
    transform: translate(15px, -30px);
  }
  87% {
    transform: translate(7px, -44px);
  }
  90% {
    transform: translate(0, -40px);
  }
  100% {
    transform: translate(0, 0);
  }
}
@-webkit-keyframes barUp1 {
  0% {
    transform: scale(1, 0.2);
  }
  40% {
    transform: scale(1, 0.2);
  }
  50% {
    transform: scale(1, 1);
  }
  90% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 0.2);
  }
}
@keyframes barUp1 {
  0% {
    transform: scale(1, 0.2);
  }
  40% {
    transform: scale(1, 0.2);
  }
  50% {
    transform: scale(1, 1);
  }
  90% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 0.2);
  }
}
@-webkit-keyframes barUp2 {
  0% {
    transform: scale(1, 0.4);
  }
  40% {
    transform: scale(1, 0.4);
  }
  50% {
    transform: scale(1, 0.8);
  }
  90% {
    transform: scale(1, 0.8);
  }
  100% {
    transform: scale(1, 0.4);
  }
}
@keyframes barUp2 {
  0% {
    transform: scale(1, 0.4);
  }
  40% {
    transform: scale(1, 0.4);
  }
  50% {
    transform: scale(1, 0.8);
  }
  90% {
    transform: scale(1, 0.8);
  }
  100% {
    transform: scale(1, 0.4);
  }
}
@-webkit-keyframes barUp3 {
  0% {
    transform: scale(1, 0.6);
  }
  100% {
    transform: scale(1, 0.6);
  }
}
@keyframes barUp3 {
  0% {
    transform: scale(1, 0.6);
  }
  100% {
    transform: scale(1, 0.6);
  }
}
@-webkit-keyframes barUp4 {
  0% {
    transform: scale(1, 0.8);
  }
  40% {
    transform: scale(1, 0.8);
  }
  50% {
    transform: scale(1, 0.4);
  }
  90% {
    transform: scale(1, 0.4);
  }
  100% {
    transform: scale(1, 0.8);
  }
}
@keyframes barUp4 {
  0% {
    transform: scale(1, 0.8);
  }
  40% {
    transform: scale(1, 0.8);
  }
  50% {
    transform: scale(1, 0.4);
  }
  90% {
    transform: scale(1, 0.4);
  }
  100% {
    transform: scale(1, 0.8);
  }
}
@-webkit-keyframes barUp5 {
  0% {
    transform: scale(1, 1);
  }
  40% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1, 0.2);
  }
  90% {
    transform: scale(1, 0.2);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes barUp5 {
  0% {
    transform: scale(1, 1);
  }
  40% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1, 0.2);
  }
  90% {
    transform: scale(1, 0.2);
  }
  100% {
    transform: scale(1, 1);
  }
}

