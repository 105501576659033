@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
:root {
  --bg: linear-gradient(to right,#68B7D9,#7AD0C6,#8AE5BA,#A8E1DB);
}
body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto',sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  background-image: var(--bg);
}